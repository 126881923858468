
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import Tagify from '@yaireo/tagify';
import roomProfitType from "@/core/data/roomprofittype";
import {dateConvert} from "@/core/filters/datatime";
import curList from "@/core/data/currency";

interface newItemData {
  itemTitle: string;
  itemStart: string;
  itemFinish: string;
  itemMarket: string;
  itemDepartment: string;
  itemFrom: string;
  itemTo: string;
  itemType: string;
  itemAmount: string;
  itemCur: string;
}

export default defineComponent({
  name: "update-item-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      startpicker: null,
      endpicker: null,
    }
  },
  methods: {
  },
  props: {
    hotelID: String,
    itemID: String,
  },
  watch: {
    itemID() {
      const payloadit = {
        "ID": this.itemID
      };
      store.dispatch(Actions.HOTEL_KICKBACKS_DETAILS, payloadit);
    },
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);


    function setDatePicker(sDate,fDate){
      let myConfigS = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        defaultDate: sDate,
      };

      let myConfigF = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        defaultDate: fDate,
      };

      let tmpFinishDate = flatpickr("#updateFinishDate", myConfigF);
      let tmpStartDate = flatpickr("#updateStartDate", myConfigS);

    }
    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });

    const myDetails = computed(() => {
      let tmpArry = store.getters.hotelKickBacksDetails;
      console.log(tmpArry);
      if(tmpArry.ID!==undefined) {
        setData(tmpArry);

        if (tmpArry.StartDate) setDatePicker(dateConvert.method.convertToDate(tmpArry.StartDate), dateConvert.method.convertToDate(tmpArry.FinishDate))
      }
      return tmpArry;

    });


    const setData = (tmpArry) => {
      newItemData.value.itemTitle = tmpArry.Title;
      newItemData.value.itemStart = dateConvert.method.dateToflatpicker(tmpArry.StartDate);
      newItemData.value.itemFinish = dateConvert.method.dateToflatpicker(tmpArry.FinishDate);
      newItemData.value.itemType = tmpArry.Type;
      newItemData.value.itemAmount = tmpArry.Amount;
      newItemData.value.itemMarket = tmpArry.Markets;
      newItemData.value.itemDepartment = tmpArry.Departments;
      newItemData.value.itemFrom = tmpArry.From;
      newItemData.value.itemTo = tmpArry.To;
      newItemData.value.itemCur = tmpArry.Cur;
    }

    const newItemData = ref<newItemData>({
      itemTitle: "",
      itemStart: "",
      itemFinish: "",
      itemMarket: "",
      itemDepartment: "",
      itemFrom: "",
      itemTo: "",
      itemType: "",
      itemAmount: "",
      itemCur: "",
    });

    const validationSchema = Yup.object().shape({
      itemTitle: Yup.string().required().label("Başlık"),
      itemStart: Yup.string().required().label("Başlangıç Tarihi"),
      itemFinish: Yup.string().required().label("Bitiş Tarihi"),
      itemType: Yup.string().required().label("Komisyon Türü"),
      itemAmount: Yup.string().required().label("Komisyon Miktarı/Oranı"),
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");


      const payload1 = {
        ID: props.itemID,
        HotelID: myDetails2.value.ID,
        Title: newItemData.value.itemTitle,
        StartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemStart),
        FinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemFinish),
        Markets: (typeof newItemData.value.itemMarket !="string") ? (JSON.stringify(newItemData.value.itemMarket)).replace(/\\/g, '') : '""',
        Departments: (typeof newItemData.value.itemDepartment !="string") ? (JSON.stringify(newItemData.value.itemDepartment)).replace(/\\/g, '') : '""',
        From: newItemData.value.itemFrom,
        To: newItemData.value.itemTo,
        Type: newItemData.value.itemType,
        Amount: newItemData.value.itemAmount,
        Cur: newItemData.value.itemCur,
      }


      store.dispatch(Actions.HOTEL_KICKBACKS_EDIT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getHotelKickBacksErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };

    /***********MARKET START*************/
    store.dispatch(Actions.MARKETS_LIST_CACHE, {});
    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });

    let marTagAddStatus = false;
    const myTagsMarkets = computed(() => {
      if (myMarkets.value.data && myDetails.value.ID) {
        let tmpList = [];
        let tmpSelList = [];
        let selData = JSON.parse(myDetails.value.Markets);
        for (let i = 0; i < myMarkets.value.data.length; i++) {
          tmpList[i] = {
            value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
            title: (myMarkets.value.data[i].ShortTitle).trim(),
            cur: myMarkets.value.data[i].Cur,
            type: 'market',
            ID: myMarkets.value.data[i].ID,
          };
          for (let is = 0; is < selData.length; is++) {
            if (selData[is].ID == myMarkets.value.data[i].ID) {
              tmpSelList[is] = {
                value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                title: (myMarkets.value.data[i].ShortTitle).trim(),
                cur: myMarkets.value.data[i].Cur,
                type: 'market',
                ID: myMarkets.value.data[i].ID,
              };
            }
          }
        }
        if(marTagAddStatus == false) {
          setTagInput(tmpList);
          marTagAddStatus=true;
        }
        let tags = (document.getElementById('myTagsMarkets') as HTMLInputElement);
        if(tags.value!='')tagifyMarket.removeAllTags();
        if(tmpSelList.length>0) {
          tagifyMarket.addTags(tmpSelList);
        }
        return tmpList;

      } else {
        return [];
      }
    });
    let tagifyMarket;
    const setTagInput = (wList) => {
      var input = document.querySelector('input[name=myTagsUpdateInput]');
// init Tagify script on the above inputs
      tagifyMarket = new Tagify(input, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        duplicates: false,
        skipInvalid: true, // do not remporarily add invalid tags
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 5,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        }
      });


    }
    function onMarketChange(e) {

      if(e.target.value !== "") {
        let tmpObj = JSON.parse(e.target.value);
        newItemData.value.itemMarket = tmpObj;
      }else{
        let tmpObj = "";
        newItemData.value.itemMarket = tmpObj;
      }
    }
    /***********MARKET END*************/

    /***********DEPARTMENTS START*************/
    store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
    const myDepartments = computed(() => {
      return store.getters.DepartmentsList;
    });
    let depTagAddStatus = false;
    const myTagsDepartments = computed(() => {
      if (myDepartments.value.data !== undefined && myDetails.value.ID) {
        let tmpList = [];
        let tmpSelList = [];
        let selData = JSON.parse(myDetails.value.Departments);
        for (let i = 0; i < myDepartments.value.data.length; i++) {
          tmpList[i] = {
            value: (myDepartments.value.data[i].Title).trim(),
            title: (myDepartments.value.data[i].Shorthand).trim(),
            type: 'Departments',
            ID: myDepartments.value.data[i].ID,
          };
          for (let is = 0; is < selData.length; is++) {
            if (selData[is].ID == myDepartments.value.data[i].ID) {
              tmpSelList[is] = {
                value: (myDepartments.value.data[i].Title).trim(),
                title: (myDepartments.value.data[i].Shorthand).trim(),
                type: 'Departments',
                ID: myDepartments.value.data[i].ID,
              };
            }

          }
        }

        if(depTagAddStatus == false){
          setTagInputDepartments(tmpList)
          depTagAddStatus=true;
        }
        let tags = (document.getElementById('myTagsDepartments') as HTMLInputElement);
        if(tags.value!='')tagifyDepartment.removeAllTags();
        if(tmpSelList.length>0) {
          tagifyDepartment.addTags(tmpSelList);
        }

        return tmpList;
      } else {
        return [];
      }
    });
    let tagifyDepartment;

    const setTagInputDepartments = (wList) => {
      var input = document.querySelector('input[name=myTagsUpdateInputDepartments]');
// init Tagify script on the above inputs
      tagifyDepartment = new Tagify(input, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        duplicates: false,
        skipInvalid: true, // do not remporarily add invalid tags
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 5,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        }
      });

    }
    function onDepartmentChange(e) {
      if(e.target.value !== "") {
        let tmpObj = JSON.parse(e.target.value);
        newItemData.value.itemDepartment = tmpObj;
      }else{
        let tmpObj = "";
        newItemData.value.itemDepartment = tmpObj;
      }
    }
    /***********DEPARTMENTS END*************/


    return {
      myDetails,
      myDetails2,
      newItemData,
      myTagsMarkets,
      myTagsDepartments,
      validationSchema,
      submit,
      onDepartmentChange,
      onMarketChange,
      submitButtonRef,
      newRoomModalRef,
      roomProfitType,
      dateConvert,
      depTagAddStatus,
      marTagAddStatus,
      curList
    };
  },
});
